<template>
  <vs-chip class="ag-grid-cell-chip success" :color="status">
    <span>{{ convertMachineStatus(params.value) }}</span>
  </vs-chip>
</template>

<script>
//import moment from 'moment'
//import i18n from '@/i18n/i18n'

export default {
  name: "MachineStatus",
  data: () => ({
      status: 'success',
      lastPing: '',
  }),
  methods: {
    convertMachineStatus(value) {
      switch (value) {
        case "0":
          this.status = 'success';
          return "OK";
        case "1":
          this.status = 'warning';
          return "Disco de entrada travado";
        case "2":
          this.status = 'warning';
          return "Serializador travado";
        case "3":
          this.status = 'warning';
          return "Moedas presas no serializador";
        case "4":
          this.status = 'warning';
          return "Moedas travadas na entrada do validador";
        case "5":
          this.status = 'warning';
          return "Travamento detectado no validador";
        case "6":
          this.status = 'warning';
          return "Moedas travadas na calha de descarte";
        case "7":
          this.status = 'warning';
          return "Problema durante a impressão";
        case "8":
          this.status = 'warning';
          return "Sistema interrompido manualmente";
        case "9":
          this.status = 'warning';
          return "Auto-diagnóstico falhou";
        case "10":
          this.status = 'warning';
          return  "Internet inacessível";
        case "11":
          this.status = 'danger';
          return "Sem energia";
        case "12":
          this.status = 'warning';
          return "Reverso do disco de entrada ignorado";
        case "13":
          this.status = 'warning';
          return  "Todas as moedas desse depósito foram rejeitadas";
        case "14":
          this.status = 'warning';
          return "Timeout do sensor de moedas do disco de entrada";
        case "15":
          this.status = 'warning';
          return "Serializador bloqueado";
        case "16":
          this.status = 'warning';
          return "Impressora temporariamente indisponível";
        case "100":
          this.status = 'danger';
          return "Perda de comunicação com o microcontrolador";
        case "101":
          this.status = 'danger';
          return "Perda de comunicação com a alavanca";
        case "102":
          this.status = 'danger';
          return "Perda de comunicação com a gaveta";
        case "103":
          this.status = 'danger';
          return "Perda de comunicação com a tela de manutenção";
        case "104":
          this.status = 'danger';
          return "Config de nacionalidade do microcontrolador incompatível";
        case "105":
          this.status = 'danger';
          return "Config de versão do microcontrolador incompatível";
        case "254":
          this.status = 'danger';
          return "Problema desconhecido do microcontrolador";
        case "255":
          this.status = 'danger';
          return "Problema desconhecido encontrado";
        case "256":
          this.status = 'danger';
          return "Problema durante a instalação da impressora";
        default:
          this.status = 'danger';
          return "Status desconhecido";
      }
    },
  },
};
</script>
