<template>
  <vs-chip class="ag-grid-cell-chip success" :color="status">
    <span>{{ convertPaperStatus(params.value) }}</span>
  </vs-chip>
</template>

<script>
//import moment from 'moment'
//import i18n from '@/i18n/i18n'

export default {
  name: "MachinePaperStatus",
  data: () => ({
      status: 'success',
      lastPing: '',
  }),
  methods: {
    convertPaperStatus(value) {
      switch(value){
        case "0": {
          this.status = 'success';
          return "OK";
        }
        case "1":{
          this.status = 'warning';
          return "Pouco Papel";
        }
        case "2": {
          this.status = 'danger';
          return "Sem papel";
        }
        default: {
          this.status = 'success';
          return value;
        }
      }
    },
  },
};
</script>
