<template lang="html">
  <div class="flex flex-col gap-y-4">
    <div class="flex flex-col ml-4">
      <h1 class="text-red font-bold">ATENÇÃO!</h1>
      <span>Esta seção está em fase de testes e alterações podem acontecer neste meio tempo.</span>
      <div class="flex flex-col mt-4 gap-y-2 px-4 py-2 bg-white rounded-lg shadow-lg">
        <strong>1 - Por que minha máquina não aparece aqui?</strong>
        <span>R: Sua máquina pode não estar aparecendo na tabela por alguns motivos, o principal é falta de comunicação com o CataCloud, recomendamos fortemente abordar este tema com a equipe de suporte <a href="https://api.whatsapp.com/send?phone=554898390022" target="_blank">clicando aqui</a>.</span>
      </div>
  </div>
    <div id="page-list">
    <div id="list-content" class="vx-card p-6 vs-con-loading__container">
      <vs-prompt
        :title="$t('actions.exportData')"
        class="export-options"
        @cancel="clearFields"
        @accept="exportToExcel"
        :accept-text="$t('actions.export')"
        :cancel-text="$t('actions.cancel')"
        @close="clearFields"
        :active.sync="activePrompt"
      >
        <vs-input
          v-model="fileName"
          :placeholder="$t('actions.enterFilename')"
          class="w-full"
        />
        <v-select
          v-model="selectedFormat"
          :clearable="false"
          :options="formats"
          class="my-4"
        />
      </vs-prompt>

      <vs-row vs-type="flex" vs-justify="flex-end">
        <vs-switch color="success" v-model="status">
          <span slot="on">{{ $t("status.active") }}</span>
          <span slot="off">{{ $t("status.inactive") }}</span>
        </vs-switch>
      </vs-row>

      <div class="flex flex-wrap justify-between items-center">
        <div class="vx-col md:w-1/4" v-if="$store.state.AppActiveUser.type > 0">
          <label class="vs-input--label">{{ $t("machine.company") }}</label>
          <v-select
            v-model="companyFilter"
            :clearable="false"
            :options="companyOptions"
            name="companyFilter"
            :searchable="false"
            :reduce="x => x.id"
            label="name"
            :data-vv-as="$t('machine.company')"
          />
        </div>

        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
        >
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            :label-placeholder="$t('navbar.search')"
          />
          <vs-button class="mb-4 md:mb-0 mt-5" @click="activePrompt = true">{{
            $t("actions.export")
          }}</vs-button>
        </div>
      </div>

      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="data"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      >
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
    </div>
  </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import i18n from "@/i18n/i18n";
import { getAgGridTranslation } from "@/utils/utils";
import vSelect from "vue-select";

// Cell Renderer
import MachinePaperStatus from "./cell-renderer/MachinePaperStatus.vue";
import MachineStatus from "./cell-renderer/MachineStatus.vue";

export default {
  components: {
    AgGridVue,
    vSelect,
    MachinePaperStatus,
    MachineStatus
  },
  data: () => ({
    data: null,
    dataRaw: null,
    status: true,
    searchQuery: "",
    companyOptions: [
      {
        id: "",
        name: i18n.t("common.all")
      }
    ],
    companyFilter: "",

    //Export2Excel
    activePrompt: false,
    fileName: "",
    formats: ["xlsx", "csv", "txt"],
    cellAutoWidth: true,
    selectedFormat: "xlsx",
    headerTitle: [
      "Id",
      "Cod",
      i18n.t("machine.serial_number"),
      i18n.t("common.description")
    ],
    headerVal: ["id", "code", "serial_number", "description"],

    // AgGrid
    gridOptions: {
      localeTextFunc: function(key, defaultValue) {
        return getAgGridTranslation(key, defaultValue);
      },
      suppressCellSelection: true
    },
    maxPageNumbers: 7,
    totalIntPages: 1,
    gridApi: null,
    defaultColDef: {
      sortable: true,
      resizable: true,
      suppressMenu: true,
      floatingFilter: true
    },
    columnDefs: [
      {
        headerName: i18n.t("common.id"),
        field: "id",
        width: 150,
        suppressSizeToFit: true,
        filter: true
      },
      {
        headerName: i18n.t("machine.serial_number"),
        field: "serial_number",
        sort: "asc",
        width: 150,
        filter: true
      },
      {
        headerName: i18n.t("common.description"),
        field: "description",
        width: 200,
        filter: true
      },
      {
        headerName: 'Papel',
        field: "catamoeda_status.paperStatus",
        width: 100,
        cellRendererFramework: "MachinePaperStatus"
      },
      {
        headerName: 'Máquina',
        field: "catamoeda_status.status",
        width: 180,
        cellRendererFramework: "MachineStatus"
      }
    ]
  }),
  watch: {
    status: function() {
      this.filterData();
    },
    "$store.state.windowWidth"(val) {
      this.resizeTable(val);
    },
    companyFilter: function() {
      this.filterData();
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.totalIntPages){
        return this.totalIntPages;
      }
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    filterData() {
      this.data =
        this.companyFilter != ""
          ? this.dataRaw.filter(
              x =>
                x.current_company == this.companyFilter &&
                x.active == this.status &&
                x.catamoeda_status.status !== "999"
            )
          : this.dataRaw.filter(x => x.active == this.status && x.catamoeda_status.status !== "999");
    },
    resizeTable(width) {
      if (width <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("actions", null);
      } else {
        this.gridOptions.columnApi.setColumnPinned("actions", "right");
        this.gridOptions.api.sizeColumnsToFit();
      }
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onCompanyFilterChange() {},
    loadCompanies() {
      let self = this;
      this.$store
        .dispatch("httpRequest", { method: "get", url: "company/all" })
        .then(data => {
          self.companyOptions = self.companyOptions.concat(
            data.filter(x => x.active)
          );
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    loadData() {
      var self = this;

      this.$vs.loading({
        container: "#list-content",
        scale: 0.5
      });

      this.$store
        .dispatch("httpRequest", { method: "get", url: "machine/report/catamoeda" })
        .then(data => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
          self.data = data.filter(x => x.active == this.status && x.catamoeda_status.status !== "999")

          self.data.forEach((machine) => {
            const contracts = machine.contracts;
            if (contracts) {
              machine.contracts = contracts.map(x => x.id);
              machine.contracts_codes = contracts.map(x => x.code).join(',');
            }
          })

          this.totalIntPages = Math.ceil(self.data.length / this.paginationPageSize);

          self.dataRaw = data;
        })
        .catch(error => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j];
        })
      );
    },
    exportToExcel() {
      import("@/vendor/Export2Excel").then(excel => {
        //const list = this.data

        //get only filtered data
        const list = [];
        this.gridOptions.api
          .getModel()
          .rootNode.childrenAfterFilter.forEach(x => {
            list.push(x.data);
          });

        const data = this.formatJson(this.headerVal, list);
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        });
        this.clearFields();
      });
    },
    clearFields() {
      (this.fileName = ""), (this.selectedFormat = "xlsx");
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$store.state.AppActiveUser.type > 0) {
      this.loadCompanies();
    }

    this.loadData();
    this.resizeTable(this.$store.state.windowWidth);
  }
};
</script>
